
.wrapper{
margin: 20px;
display: flex;
flex-direction: column;
justify-content: space-around;
}

.verifying{
    color:#00bcd4;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    font-weight: 500;
}

.rectangle-210{
    margin: auto;
}

.btn-close{
--bs-btn-close-focus-shadow:"none" !important;
--bs-btn-close-opacity:0.8 !important
}

.modal-header{
  border-bottom:p0px !important;
}

.modal-title{
  font-size: 17px;
  font-weight: bolder;
  letter-spacing: 0.5px;
}

.modal-backdrop{
  --bs-backdrop-opacity: 0.9 !important;
  --bs-backddrop-blur:0.6 !important
}

.model{
  backdrop-filter: "blue(10px" !important;
}



.rectangle-210-with-verified-data{
    margin: auto;
    display: flex;
    align-items: center;
}

.modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-dialog {
    max-width: 400px; /* Adjust as needed */
    margin: auto;
  }
  
  .bg-skyblue {
    background-color: rgb(33, 170, 175);
    
  }
  
  .bg-white {
    background-color: white;
  }
  
  .text-white {
    color: white;
  }
  
  .text-dark {
    color: black;
  }

  .modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set the modal height to the full viewport height */
  }
  
  .modal-dialog {
    max-width: 400px; /* Adjust as needed */
    margin: auto;
  }

  .modal.fade.show {
    background-color: #E5E5E5 /* Example: Darken the background */
  }
  
  .modal-content {
    background-color:#E5E5E5;
  }

    .modal-content-style{
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24), 0 0 8px 0 rgba(0, 0, 0, .12);
    }

  /* Define styles for the OK button */
.ok_button {
    background-color: skyblue;
    border: 2px solid skyblue;
    border-radius: 5px solid blue;
    transition: transform 0.3s ease-in-out; /* Add transition effect for transform */
  }
  
  /* Apply hover effect on the OK button */
  .ok_button:hover {
    transform: scale(1.1); /* Scale up the button on hover */
    border: 5px solid skyblue;
  }
  
  /* Apply focus effect on the OK button */
  .ok_button:focus {
    outline: none; /* Remove default focus outline */
    border-color: darkblue; /* Change border color on focus */
  }
  
  /* Style for modal */
  .modal-dialog {
    max-width: 400px; /* Adjust as needed */
    margin: auto;
  }

  .thank-you-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050; /* Adjust as needed */

  }
  
  