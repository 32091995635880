.loading {
    display: flex;
    justify-content: center;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 78px;
    height: 60px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.7s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.7s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.7s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.7s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .custom-backdrop {
    background-color: rgb(8, 7, 7) !important;
  }


  .btn-upload{
    display: flex;
    justify-content: space-evenly;
  }

  .upload-button{
    width: 12rem;
    height: 2.5rem;
    border: 1px solid var(--modal-color);
    
    
  }

  #email::placeholder{
    color: rgb(184, 179, 179);
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }

  #description::placeholder{
    color: rgb(184, 179, 179);
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  .model .fade .show{
    backdrop-filter: saturate(176%) blur(8px);
  }

  .upload-button:hover,
  .upload-button:focus {
    /* Add styles for hover and focus states */
    transform: translateY(2px); /* Move button down slightly on hover/focus */
    box-shadow: 0 0 2px var(--modal-color); /* Add box shadow */
  }
  .upload-button:active {
    /* Add styles for active/clicked state */
    transform: translateY(4px); /* Move button further down on click */
    box-shadow: none; /* Remove box shadow */
  }
  
  .thankYou-body{
    display: flex;
    flex-direction: column;
    gap:30px
  }