.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-content-style{
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .24), 0 0 8px 0 rgba(0, 0, 0, .12);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup {
  width: 475px;
  margin: auto;
}

@media (max-width: 475px) {
  .popup {
    width: 95%;
  }
}

.small-popup {
  width:315px;
  margin: auto;
}

@media (max-width: 315px) {
  .small-popup {
    width: 95%;
  }
}
