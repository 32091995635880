
:root {
  --default-color:#000000; /* black */
  --primary-color: #007bff; /* blue */
  --success-color: #00CD52; /* green */
  --danger-color: #dc3545; /* red */
  --default-checkSvg-color:rgb(0, 0, 0);
  --default-text-color: #ffffff;
  /* Add more color variables as needed */
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,'Montserrat','Prata', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New','Montserrat', sans-serif,monospace;
}
