
.uploader-powered {
  text-align: end;
  font-weight: 600;
  font-size: 0.8rem;
}
.uploader-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#browse {
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  background: black;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  /* width: 50% !important; */
  margin-inline: auto;
  transition: .3s;
}

#browse:hover {
  animation: pulse 1s infinite;
  transition: .3s;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
    100% {
    transform: scale(1);
  }
}


.see-preview:hover{
  text-decoration: underline;
  cursor: pointer;
}

.shopping-cart {
  display: inline-block;
  margin: 10px;
}

.cart-svg {
  width: 24px;
  height: 24px;
  fill: #333; /* Set icon color */
}

.cart-svg text {
  font-family: Arial, sans-serif;
  font-size: 12px;
  fill: #fff; /* Set text color */
}