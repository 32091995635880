.upload-text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.upload-title-text{
    font-size: 20px;
    font-weight: 600;
}

.upload-title-upload{
    cursor: pointer;
    text-decoration: underline;
}

.upload-title-upload:hover{
    color: #007bff;
}

.upload-footer-text{
    font-size: 16px;
    font-weight: 400;
}