.homepage {
  height: 100%;
  width: 100%;
}

.homepage-content {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  gap: 30px;
  height: 80vh;
}

.homepage-content-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
}

.homepage-upload-heading {
  /* margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
  margin-bottom: 1rem; */

  font-family: "Prata", serif;
  font-size: 2rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.verify-heading {
  font-family: "Prata", serif;
}

.content-card-wrap {
  display: flex;
  margin: auto;
  width: 70%;
}

.starting-info-showing-card .card-title {
  font-size: 20px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 15px;
}

@media screen and (max-width: 995px) {
  .content-card-wrap {
    display: flex;
    margin: auto;
    width: 90%;
  }
}
