.footer {
  width: 100%;
  margin-top: 30px;
  /* background:#f7f7f7; */
  /* background:#f7f7f7; */
}

.footer-text {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* background:#f7f7f7; */
  /* margin-top: 2rem; */
  /* background:#f7f7f7; */
  /* padding-top:2rem; */
  /* padding-bottom: 1rem; */
  /* margin-top:3rem */
}
.fixed-bottom{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    /* display: flex !important;
    justify-content: space-around !important;
    align-items: end; */
    /* background:#f7f7f7; */
}
@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-3px);
  }
  40% {
    transform: translateX(3px);
  }
  60% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.vibrate-animation {
  animation: vibrate 0.5s infinite;
}
